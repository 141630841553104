<template>
  <div id="app">
    <div class="container">
      <!-- 查询行 -->
      <el-row type="flex" justify :gutter="10">
        <!-- 日期选择器 -->
        <el-col :span="5.5">
          <el-date-picker
            v-model="pickerValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dataChange"
          >
          </el-date-picker>
          <el-button style="margin-left: 10px;" @click="queryData" type="primary">查询</el-button>
        </el-col>
        <!-- 导出excel按钮 -->
        <el-col :span="0.5">
          <el-button @click="exportExcel" type="primary">导出Excel</el-button>
        </el-col>
        <!-- 表格数据来源标明 -->
        <el-col :span="6" :push="13">
          <span style="color: aquamarine;line-height:40px;">注：本表数据来源于每日用水量上传</span>
        </el-col>
      </el-row>
      <!-- 表格部分 -->
      <div
        class="content"
        v-loading="!isShow"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <el-table border  ref="table" :data="tableData" height="100%" v-if="isShow">
          <el-table-column
            type="index"
            label="序号"
            width="55"
            align="center"
            fixed
          >
          </el-table-column>
          <el-table-column
            prop="coding"
            label="编号"
            width="120"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="deviceId"
            label="IMEI"
            width="180"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="deviceName"
            label="设备名称"
            width="220"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column label="当前状态" width="150" align="center" fixed>
            <template slot-scope="scope">
              <div :style="scope.row.state == '失联' ? 'color:red' : ''">
                {{ scope.row.state }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, i) in timeList"
            :key="i"
            :label="item"
            align="center"
            width="110"
          >
          <!-- :style="stateStyle(scope)" -->
            <template slot-scope="scope">
              <!-- <div :style="scope.row.statusList.includes(scope.column.label)? '': 'color:red'"> -->
              <div :style="scope.row.statusList.includes(scope.column.label)? '': 'color:red'">
                {{ scope.row.valueList[i] }} m³
              </div>
              <!-- <div >{{ scope.row.valueList[i] }} m³</div> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
// import companySystem from "@/api/managementApi/CompanyInfo";
import DeviceHistoryValue from "@/api/manageApi/DeviceHistoryValue";
import DeviceInfoManagement from "@/api/manageApi/DeviceInfoManagement";
import { export_json_to_excel } from "../../../introduce/Export2Excel";

export default {
  components: {},
  props: {},
  data() {
    return {
      // 日期选择器默认时间
      pickerValue: [],
      // 表格数据绑定
      tableData: [],
      timeList: [],
      //公司列表
      allCompanyList: [],
      // 设备状态
      deviceIds: [],
      isShow: false,
    };
  },
  created() {
    // 设定默认日期
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let month1 = date.getMonth() + 1;
    let day = "20";
    let time = "00:00:00";
    if (month < 10) {
      month = "0" + month;
    }
    if (month1 < 10) {
      month1 = "0" + month1;
    }
    let startTime = year + "-" + month + "-" + day + " " + time;
    let endTime = year + "-" + month1 + "-" + day + " " + time;
    this.pickerValue[0] = startTime;
    this.pickerValue[1] = endTime;

    this.getDeviceInfo();
  },
  mounted() {},
  methods: {
    dataChange(value) {
      // 格式化日期和时间
      this.pickerValue[0] = this.$moment(value[0]).format("YYYY-MM-DD");
      this.pickerValue[1] = this.$moment(value[1]).format("YYYY-MM-DD");
      // console.log(value);
    },
    // 查历史数据
    async getDeviceInfo() {
      let obj = {};
      obj.current = 1;
      obj.limit = 1000;
      obj.type = "Daily";
      obj.deviceTypeId = "DXwaterMeter";
      obj.startTime = this.pickerValue[0];
      obj.endTime = this.pickerValue[1];
      const res = await DeviceHistoryValue.getWaterMeterData(obj);
      // 赋值
      this.tableData = res.data.data.list;

      this.timeList = this.tableData[0].timeList;

      this.checkEquipmentStatus();
      // 表格重绘，解决错位
      this.$nextTick(() => {
        this.$refs.table.doLayout();
      });
    },
    // 查设备状态（回调函数）
    checkEquipmentStatus() {
      this.deviceIds = [];
      for (let i = 0; i < this.tableData.length; i++) {
        this.deviceIds.push(this.tableData[i].deviceId);
      }
      let obj = {};
      obj.deviceIds = this.deviceIds;
      // const res = await DeviceInfoManagement.checkEquipmentStatus(obj);
      DeviceInfoManagement.checkEquipmentStatus(obj).then((res) => {
        const status = res.data.data;
        // 拿到的map
        // console.log(status);
        // 添加新字段
        // 遍历表格绑定的数据
        for (let j = 0; j < this.tableData.length; j++) {
          // console.log(this.tableData[j].deviceId);
          // 遍历map
          for (let i in status) {
            // console.log(i);
            if (i == this.tableData[j].deviceId) {
              // 添加字段state
              this.tableData[j].state = status[i];
            }
          }
        }
        // console.log(this.tableData);

        this.tableData.sort((a, b) => a.number - b.number);

        console.log(this.tableData);

        this.isShow = true;
      });
    },
    // 导出表格
    exportExcel() {
      // 给出一个新的数组用来接收time和value,格式化数据
      let newArr = Object.assign(this.tableData);
      for (let i = 0; i < newArr.length; i++) {
        for (let j = 0; j < newArr[i].timeList.length; j++) {
          newArr[i][newArr[i].timeList[j]] = newArr[i].valueList[j];
        }
      }
      // console.log(newArr);
      // console.log(this.tableData[0]);
      // 设置表格
      let tHeaders = ["设备编号", "设备名称", "当前状态"];
      let filterVals = ["deviceId", "deviceName", "state"];
      for (const key in this.tableData[0]) {
        for (let i = 0; i < this.tableData[0].timeList.length; i++) {
          if (key == this.tableData[0].timeList[i]) {
            tHeaders.push(key);
            filterVals.push(key);
          }
        }
      }
      // console.log(tHeaders);
      // console.log(filterVals);

      require.ensure([], () => {
        const tHeader = tHeaders; // 对应表格输出的中文title
        const filterVal = filterVals; // 对应表格输出的数据

        const data = this.formatJson(filterVal, newArr);

        export_json_to_excel(tHeader, data, "用水明细"); // 对应下载文件的名字
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    //查询
    queryData() {
      this.getDeviceInfo();
      // 表格的重绘
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/tableCss.scss";
.container {
  box-sizing: border-box;
  padding: 90px 10px 0 10px;
  height: 100%;
  color: #ccc;
  .content{
    height: 93%;
  }
}
/deep/.el-table__body tr:hover>td {
    background-color:transparent !important;
    }
</style>